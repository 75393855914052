<template>
  <div class="container">
    <div class="d-flex justify-space-between mb-9">
      <h2 class="page-header">Jobs In Progress</h2>
      <v-btn
        @click="DownloadPDF('byEngineer/pdf')"
        :loading="this.is_loading"
        :disabled="this.is_loading"
        >Download as PDF</v-btn
      >
    </div>
    <div>
      <!-- <TableJobsByEngineer
        v-if="JobsByEngineer && JobsByEngineer.length > 0"
        :JobsByEngineer="JobsByEngineer"
      /> -->

      <v-data-table
        :headers="headers"
        :items="JobsByEngineer"
        :items-per-page="-1"
        class="elevation-1"
        style="width: 100%"
        group-by="tag.name"
        :loading="is_loading"
        mobile-breakpoint="0"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn :to="'/job/' + item.id" color="primary">View</v-btn>
        </template>

        <!-- <template v-slot:group.header="{ items }">
          <th colspan="5">
            {{
              items[0].user && items[0].user.name
                ? items[0].user.name
                : "No User Assigned"
            }}
          </th>
        </template> -->

        <template v-slot:group="{ items }">
          <tr colspan="7">
            <td colspan="7" class="grey lighten-2" style="font-weight: 500">
              <!-- {{ items[0].user && items[0].user.name ? items[0].user.name : 'No User Assigned' }} -->
              {{
                items[0].tag_id && items[0].tag.name ? items[0].tag.name : 'No Department Assigned'
              }}
            </td>
          </tr>
          <tr v-for="item in items" :key="item.id">
            <td v-for="header in headers" :key="item.id + ' - ' + header.value">
              <span v-if="header.value == 'staDate'">
                {{ item.staDate | YMD_to_DMY }}
              </span>

              <span v-else-if="header.value == 'user_name'">
                {{ item.user && item.user.name ? item.user.name : 'No Engineer Assigned' }}
              </span>

              <span v-else-if="header.value == 'NetTotal'">
                {{ item.NetTotal | toCurrency }}
              </span>

              <v-btn :to="'/job/' + item.id" color="primary" v-else-if="header.value == 'actions'"
                >View</v-btn
              >

              <span v-else>
                {{
                  header.value == 'company'
                    ? item['company']
                      ? item[header.value]['name']
                      : ''
                    : item[header.value]
                }}</span
              >
            </td>
          </tr>
          <tr v-if="items && items.length > 0" class="">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>

            <td>
              <p class="ma-0">
                Total:
                <strong>{{ CalculateJobsAssignedTotal(items) | toCurrency }}</strong>
              </p>
            </td>
            <td></td>
          </tr>
        </template>

        <template slot="body.append">
          <tr class="grey lighten-1">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <strong>Total: {{ CalculateJobsAssignedTotal(JobsByEngineer) | toCurrency }}</strong>
            </td>
            <td></td>
          </tr>
        </template>
      </v-data-table>
      <div></div>
    </div>
  </div>
</template>

<script>
// import TableJobsByEngineer from "../../components/reports/TableJobsByEngineer";

export default {
  name: 'jobsByEngineer',
  data() {
    return {
      JobsByEngineer: [],
      is_loading: false,
      headers: [
        {
          text: 'Job',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Customer', value: 'company' },
        { text: 'Description', value: 'description' },
        { text: 'Engineer', value: 'user_name', sortable: true },
        { text: 'Start Date', value: 'staDate', width: '150' },
        { text: '£ Net', value: 'NetTotal' },
        { text: 'Actions', value: 'actions' },
      ],
    };
  },
  components: {
    // TableJobsByEngineer,
  },
  methods: {
    DownloadPDF(endpoint) {
      this.is_loading = true;
      return this.$store.dispatch('DownloadReportPDF', endpoint).finally(() => {
        this.is_loading = false;
      });
    },
    CalculateJobsAssignedTotal(jobs) {
      var total = 0;
      var i = 0;
      for (i == 0; i < jobs.length; i++) {
        total += jobs[i].NetTotal || 0;
      }
      return total;
    },
  },
  mounted() {
    this.$store.dispatch('get_report_data_for_tables', 'byEngineer').then((res) => {
      this.JobsByEngineer = res;
    });
  },
};
</script>
