<template>
  <div id="charts" v-if="this.pie_chart_store_data.length > 0">
    <div>
      <v-row class="ma-0 pa-4">
        <v-col cols="12" class="mb-5" md="5">
          <h3 class="mb-3">Jobs In Progress</h3>
          <JobsInProgress v-if="this.pie_chart_store_data.length > 0" />
        </v-col>
        <v-col cols="2" class="d-none d-md-flex"></v-col>
        <v-col cols="12" class="mb-5" md="5">
          <h3 class="mb-3">Job Warranties by Month</h3>
          <WarrantiesChart v-if="this.warranties_store_obj.length > 0" />
        </v-col>
        <v-col cols="12" class="mb-5" md="5">
          <h3 class="mb-3">Job Conversions by Month</h3>
          <ConversionsChart v-if="this.conversions_store_data.length > 0" />
        </v-col>
        <v-col cols="2" class="d-none d-md-flex"></v-col>

        <v-col cols="12" class="mb-5" md="5">
          <StatCard
            :cardTitle="'Invoice-able jobs'"
            :value="invoiceable_jobs"
            :label="'Invoice-able jobs'"
          />
          <div class="mt-4"></div>
          <StatCard
            :cardTitle="'Total Outstanding order numbers'"
            :value="outstandingOrders"
            :label="'Outstanding Orders'"
            :linkTo="'/reports/awaiting_po'"
          />
          <div class="mt-4"></div>
          <StatCard
            :cardTitle="'Jobs Booked in this month'"
            :value="monthly_booked_in_jobs"
            :label="'Jobs Booked in this month'"
            :linkTo="'/reports/booked_in_by_month/'"
          />
        </v-col>

        <v-col cols="12" class="mb-5" md="5">
          <StatCard
            :cardTitle="'Jobs Awaiting Acceptance'"
            :value="awaitAcc"
            :label="'As of ' + awaitAccDate"
            :linkTo="'/reports/awaiting_acceptance/'"
          />
          <div class="mt-4"></div>

          <StatCard
            :cardTitle="'Jobs Awaiting Acceptance > 30 days'"
            :value="awaitAcc30"
            :label="'Jobs Awaiting Acceptance > 30 days'"
            :linkTo="'/reports/awaiting_acceptance/'"
          />
        </v-col>

        <v-col cols="2" class="d-none d-md-flex"></v-col>

        <v-col cols="12" class="mb-5" md="5">
          <StatCard
            :cardTitle="'Commencement longer than quoted'"
            :value="jobsOverdue"
            :label="'Jobs Overdue'"
            :linkTo="'/reports/go_ahead'"
          />
        </v-col>

        <v-col cols="12" md="5">
          <div class="stat-card">
            <h3 class="mb-3">Job Totals by Status</h3>
            <div class="stat-card-content d-flex flex-row justify-space-between align-center">
              <div>Go Ahead</div>
              <div class=" stat-value" v-if="this.goAheadTotal">{{ this.goAheadTotal }}</div>
            </div>

            <div class="stat-card-content d-flex flex-row justify-space-between align-center">
              <div>In Progress</div>
              <div class=" stat-value" v-if="this.inProgressTotal">{{ this.inProgressTotal }}</div>
            </div>

            <div class="stat-card-content d-flex flex-row justify-space-between align-center">
              <div>For Delivery</div>
              <div class=" stat-value" v-if="this.forDeliveryTotal">
                {{ this.forDeliveryTotal }}
              </div>
            </div>

            <div class="stat-card-content d-flex flex-row justify-space-between align-center">
              <div>For Invoicing</div>
              <div class=" stat-value" v-if="this.forInvoicingTotal">
                {{ this.forInvoicingTotal }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
  <div style="height: 100%" v-else>
    <LoadingScreen />
  </div>
</template>

<script>
import WarrantiesChart from '../../components/management/stats/WarrantiesChart.vue';
import ConversionsChart from '../../components/management/stats/ConversionsChart.vue';
import JobsInProgress from '../../components/management/stats/JobsInProgress.vue';
import StatCard from '../../components/management/stats/StatCard.vue';
import LoadingScreen from '../../components/global/LoadingScreen.vue';

export default {
  name: 'StatsDashboard',
  components: {
    WarrantiesChart,
    ConversionsChart,
    JobsInProgress,
    StatCard,
    LoadingScreen,
  },

  data() {
    return {
      is_loading: true,
    };
  },
  mounted() {
    this.$store
      .dispatch('get_stats_data')
      .then(() => {
        this.is_loading = false;
      })
      .catch(() => {
        // this.$store.commit("SetSnackbarData", {
        //   message: error,
        //   type: "error",
        // });
      });
  },
  computed: {
    warranties_store_obj() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats['warranties']) return Stats['warranties'];
      else return [];
    },
    conversions_store_data() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats['jobConversions']) return Stats['jobConversions'];
      else return [];
    },
    pie_chart_store_data() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats['pie']) return Stats['pie'];
      else return [];
    },
    outstandingOrders() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats['outstandingOrders']) return Stats['outstandingOrders'];
      else return '';
    },
    invoiceable_jobs() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats['invoiceable_jobs'])
        return this.$options.filters.toCurrency(Stats['invoiceable_jobs']);
      else return '£0';
    },
    monthly_booked_in_jobs() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats['monthly_booked_in_jobs']) return Stats['monthly_booked_in_jobs'];
      else return '';
    },
    awaitAcc() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats['awaitAcc']) return Stats['awaitAcc'];
      else return '';
    },
    awaitAcc30() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats['awaitAcc30']) return Stats['awaitAcc30'];
      else return '';
    },
    awaitAccDate() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats['awaitAccDate']) return Stats['awaitAccDate'];
      else return '';
    },
    jobsOverdue() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats['jobsOverdue']) return Stats['jobsOverdue'];
      else return '0';
    },
    goAheadTotal() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats['goAhead_total_sales']) return '£' + Stats['goAhead_total_sales'];
      else return '£0';
    },
    inProgressTotal() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats['inProgress_total_sales']) return '£' + Stats['inProgress_total_sales'];
      else return '£0';
    },
    forDeliveryTotal() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats['forDelivery_total_sales']) return '£' + Stats['forDelivery_total_sales'];
      else return '£0';
    },
    forInvoicingTotal() {
      const Stats = this.$store.getters.getStatsData;
      if (Stats && Stats['forInvoicing_total_sales'])
        return '£' + Stats['forInvoicing_total_sales'];
      else return '£0';
    },
  },
};
</script>

<style></style>
