<template>
  <v-col class="px-5" v-if="this.job" md="3" sm="6" cols="12">
    <div class="table-cont mb-5">
      <div class="table-header pa-3 d-flex">
        <div class="table-property-label">Status</div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Job Status</div>
        <div class="table-property-value">{{ this.job.status.name }}</div>
      </div>

      <div class="table-row">
        <div class="table-property-label">In Progress Status</div>
        <div class="table-property-value">
          {{ this.job.is_working_on ? 'Yes' : 'No' }}
          <v-btn
            class="px-2 py-0 ml-6"
            style="font-size: 12px"
            color="success"
            :loading="this.isSubmitting"
            :disabled="this.isSubmitting"
            @click="SetPausedStatus()"
          >
            {{ this.job.is_working_on ? 'Pause' : 'Un-pause' }}
          </v-btn>
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Due Date</div>
        <div class="table-property-value">{{ this.dueDate }}</div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Sub-Contractor</div>
        <div class="table-property-value">
          {{
            this.job.subcontractor && this.job.subcontractor.name ? this.job.subcontractor.name : ''
          }}
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Under Warranty</div>
        <div class="table-property-value">
          {{ this.job.is_warranty ? 'Yes' : 'No' }}
          <v-btn
            class="px-2 py-0 ml-6"
            style="font-size: 12px"
            color="success"
            :loading="this.isSubmitting"
            :disabled="this.isSubmitting"
            @click="SetWarranty()"
            >Switch</v-btn
          >
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Parts on order</div>
        <div class="table-property-value">
          {{ this.job.is_awaiting_parts ? 'Yes' : 'No' }}
          <v-btn
            class="px-2 py-0 ml-6"
            style="font-size: 12px"
            color="success"
            :loading="this.isSubmitting"
            :disabled="this.isSubmitting"
            @click="SetAwaitingParts()"
            >Switch</v-btn
          >
        </div>
      </div>

      <div class="table-row">
        <div class="table-property-label">Sage Exported</div>
        <div class="table-property-value">
          {{ this.job.is_exported ? 'Yes' : 'No' }}
        </div>
      </div>
    </div>
    <PauseJobModal v-model="jobPauseModal" :job_id="this.job.id" />
    <JobInternalNote />
  </v-col>
</template>

<script>
import PauseJobModal from './PauseJobModal';
import JobInternalNote from './JobInternalNote';
export default {
  name: 'JobStatusInfo',
  components: {
    PauseJobModal,
    JobInternalNote,
  },
  data() {
    return {
      isSubmitting: false,
      jobPauseModal: false,
    };
  },
  methods: {
    SetPausedStatus() {
      if (!this.job.id) return;

      if (this.job.is_working_on) this.jobPauseModal = true;
      else
        this.$store
          .dispatch('switchJobPausedStatus', {
            id: this.job.id,
            comments: '',
          })
          .then(() => {
            this.$store.commit('SetSnackbarData', {
              message: 'The Job has been ' + 'un-paused',
            });
          })
          .finally(() => {
            this.isSubmitting = false;
          });
    },
    SetWarranty() {
      var value = true;
      if (this.job.id) {
        this.isSubmitting = true;

        if (this.job.is_warranty == true || this.job.is_warranty == 1) value = false;

        var order_number = '';
        if (value == true) order_number = 'Under Warranty';

        let payload = {
          id: this.job.id,
          is_warranty: value,
          order_number: order_number,
        };

        if (value == true && this.job.status_id < 5) payload['status_id'] = 5;
        //turned on but not yet go ahead
        else if (value == false) payload['status_id'] = 2; //turned off

        this.$store
          .dispatch('updateSingleJob', payload)
          .then(() => {
            this.$store.commit('SetSnackbarData', {
              message: 'Warranty status updated successfully.',
            });
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    SetAwaitingParts() {
      var value = true;
      if (this.job.id) {
        this.isSubmitting = true;

        if (this.job.is_awaiting_parts == true || this.job.is_awaiting_parts == 1) value = false;


        let payload = {
          id: this.job.id,
          is_awaiting_parts: value,
        };

        this.$store
          .dispatch('updateSingleJob', payload)
          .then(() => {
            this.$store.commit('SetSnackbarData', {
              message: 'Awaiting Parts status updated successfully.',
            });
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
  },
  computed: {
    dueDate() {
      if (this.job.status_id < 5) return 'N/A';
      var Deltime = parseInt(this.job.salDeltime) || 0;
      var started = new Date(this.job.appDate) || -1;
      if (started == -1) return 'error';
      started.setDate(started.getDate() + Deltime);
      // return started.getTime() / 1000;
      // var formattedDate =
      //       started.getUTCDate() +
      //       "-" +
      //       (started.getUTCMonth() + 1) +
      //       "-" +
      //       started.getUTCFullYear();
      return started.toLocaleString('en-us', {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
      });
    },
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
  },
};
</script>

<style></style>
