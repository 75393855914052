var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.CurrentUser['col_' + this.col_Id])?_c('v-col',{staticClass:"dash-col"},[_c('p',{staticClass:"riy primary darken-3 white--text",on:{"click":function($event){return _vm.test()}}},[_vm._v(" "+_vm._s(_vm.ColumnName(this.col_Id))+" - "+_vm._s(this.jobs ? this.jobs.length : 'N/A')+" ")]),_vm._l((_vm.filtered_assigned_user),function(job,index){return _c('div',{key:job.id,ref:job.id,refInFor:true,staticClass:"job-card my-4",attrs:{"data-index":index,"data-status_id":job.status_id,"id":job.id},on:{"click":function($event){return _vm.goToJob(job.id)}}},[_c('div',{staticClass:"job-card-top-row d-flex justify-space-between",class:{
        'normal-priority': job.priority_id <= 2,
        'high-priority': job.priority_id == 3,
        'emergency-priority': job.priority_id == 4,
      }},[_vm._v(" "+_vm._s(job.id)+" "),(
          job &&
            job.company &&
            ((job.company.term_id && job.company.term_id == 1) ||
              (job.company.company_status_id && job.company.company_status_id == 5))
        )?_c('span',{staticClass:"is_cash_only_badge py-1 px-2 d-flex"},[_vm._v(" PROFORMA ")]):_vm._e(),(job.bBer || job.bRnr || job.company.company_status_id == 2)?_c('div',{staticClass:"header-roles-container d-flex"},[(job.bBer)?_c('div',{staticClass:"header-role-chip mx-1 px-4 py-1",staticStyle:{"background":"rgba(0, 0, 0, 0.5)"}},[_vm._v(" BER ")]):_vm._e(),(job.bRnr)?_c('div',{staticClass:"header-role-chip mx-1 px-4 py-1 "},[_vm._v("RNR")]):_vm._e(),(job.company.company_status_id == 2)?_c('div',{staticClass:"header-role-chip mx-1 px-4 py-1 css-animate-blink",staticStyle:{"background":"rgba(0, 0, 0, 0.75)"}},[_vm._v(" On stop ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"job-card-content-row d-flex flex-column"},[_c('p',{staticClass:"mb-2 body-2"},[_c('span',{staticClass:"mr-2",staticStyle:{"line-height":"1.75"}},[_vm._v(_vm._s(job.company && job.company.name ? job.company.name : 'N/A'))]),(
            job &&
              job.company &&
              job.company.company_status_id &&
              job.company.company_status_id == 3
          )?_c('span',{staticClass:"is_cash_only_badge py-1 px-2 d-flex",staticStyle:{"background":"#e77b20"}},[_vm._v(" PO Required ")]):_vm._e(),(
            job &&
              job.company &&
              job.company.company_status_id &&
              job.company.company_status_id == 4
          )?_c('span',{staticClass:"is_cash_only_badge py-1 px-2 d-flex",staticStyle:{"background":"#e77b20"}},[_vm._v(" Authorisation Required ")]):_vm._e()]),(job.manufacturer || job.machine_model)?_c('hr'):_vm._e(),(job.manufacturer)?_c('p',{staticClass:"mb-2 mt-1 body-2"},[_vm._v(" "+_vm._s(job.manufacturer)+" ")]):_vm._e(),(job.machine_model)?_c('p',{staticClass:"mb-2 body-2"},[_vm._v(" "+_vm._s(job.machine_model)+" ")]):_vm._e(),(job.description)?_c('hr'):_vm._e(),(job.description)?_c('p',{staticClass:"mb-2 mt-1 body-2"},[_vm._v(" "+_vm._s(job.description)+" ")]):_vm._e(),(job.order_number && !job.is_warranty)?_c('hr'):_vm._e(),(job.order_number && !job.is_warranty)?_c('p',{staticClass:"mb-1 font-weight-medium caption mt-1 primary--text body-2"},[_vm._v(" "+_vm._s(job.order_number)+" ")]):_vm._e(),(job.is_warranty || job.is_awaiting_parts)?_c('hr'):_vm._e(),(job.is_warranty || job.is_awaiting_parts)?_c('div',{staticClass:"d-flex",staticStyle:{"gap":"14px"}},[(job.is_warranty)?_c('div',{staticClass:"is_warranty_badge py-1 px-2 d-flex mt-2"},[_vm._v(" Under Warranty ")]):_vm._e(),(job.is_awaiting_parts)?_c('div',{staticClass:"is_warranty_badge is_awaiting_parts_badge py-1 px-2 d-flex mt-2"},[_vm._v(" Parts On Order ")]):_vm._e()]):_vm._e(),(job.appDate && (job.status_id == 5 || job.status_id == 6))?_c('div',{staticClass:"appDate_card_badge py-1 px-2 d-flex mt-1 mr-auto",staticStyle:{"font-size":"12px"},attrs:{"title":"Approved date"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"white"}},[_vm._v("mdi-calendar-month")]),_vm._v(" "+_vm._s(_vm._f("YMD_to_DMY")(job.appDate))+" ")],1):_vm._e()])])})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }